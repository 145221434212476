import React from "react";
import { FaLinkedin, FaTwitter, FaFacebook } from "react-icons/fa";
import { FaGoogle } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";

const Footer1 = () => {
  return (
    <>
      <div className="px-4 py-8 footer_container bg-[#18181A] text-[#abaaaa] w-full">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 justify-items-center">
          <div className="mt-5 text-center lg:text-left">
            <img
              src="/Images/4.png"
              alt="logo"
              className="h-48 w-48 mx-auto lg:mx-0 md:w-44 md:h-44 lg:w-40 lg:h-40"
            />
            <p className=" leading-loose font-normal">
              AB Security is a top-notch security company providing the best
              security services across India.
            </p>
            <div className="flex justify-center lg:justify-start gap-6 pt-6 cursor-pointer">
              <FaGoogle className="w-6 h-6 text-[#abaaaa] hover:text-[#F68400]" />
              <FaTwitter className="w-6 h-6 text-[#abaaaa] hover:text-[#F68400]" />
              <FaFacebook className="w-6 h-6 text-[#abaaaa] hover:text-[#F68400]" />
              <FaLinkedin className="w-6 h-6 text-[#abaaaa] hover:text-[#F68400]" />
            </div>
          </div>

          <div className="mt-4 text-center md:text-center lg:text-left">
            <h1 className="pb-4 font-semibold text-xl">Services</h1>
            <ul className="space-y-2">
              <li className="hover:text-[#F68400]">
                <a href="/services">Hotel Security Services</a>
              </li>
              <li className="hover:text-[#F68400]">
                <a href="/services">Industrial Security Services</a>
              </li>
              <li className="hover:text-[#F68400]">
                <a href="/services">Corporate Security Services</a>
              </li>
              <li className="hover:text-[#F68400]">
                <a href="/services">Personal Security Services</a>
              </li>
              <li className="hover:text-[#F68400]">
                <a href="/services">Dog Squad Services</a>
              </li>
              <li className="hover:text-[#F68400]">
                <a href="/services">Bank Security Services</a>
              </li>
              <li className="hover:text-[#F68400]">
                <a href="/services">Lady Security Services</a>
              </li>
              <li className="hover:text-[#F68400]">
                <a href="/services">Fire Squad Services</a>
              </li>
            </ul>
          </div>

          <div className="mt-4 text-center md:text-center lg:text-left">
            <h1 className="pb-4 font-semibold text-xl">Company</h1>
            <ul className="space-y-2">
              <li className="hover:text-[#F68400]">
                <a href="/about">About Us</a>
              </li>
              <li className="hover:text-[#F68400]">
                <a href="/Contact">Contact Us</a>
              </li>
            </ul>
          </div>

          <div className="mt-4 text-center md:text-center lg:text-left">
            <h1 className="pb-4 font-semibold text-xl">Address</h1>
            <p className="leading-loose font-normal">
              <span className="font-semibold hover:text-[#F68400]">
                AB Security and Placement Services
              </span>
              <br />
              KHS - 208, OD - 100127, Baidpura, Greater Noida,
              <br />
              Gautam Buddha Nagar, Uttar Pradesh - 203207, India
            </p>
            <div className="flex justify-center lg:justify-start items-center gap-4 mt-6">
              <FaPhoneAlt className="text-[#F68400]" />
              <div className="text-[#F68400]">
                <p>+91 92898 15124</p>
                <p>+91 98732 22202</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr className="border-gray-500" />
      <div className="bg-[#18181A] text-[#abaaaa] py-6 text-center">
        <p className="text-sm">
          Copyright &copy; 2020-2024 All rights reserved | Developed with
          &hearts; by <span className="font-bold">OLBUIZ</span>
        </p>
      </div>
    </>
  );
};

export default Footer1;
