import React from "react";

const Services = () => {
  return (
    <div className="pt-8 justify-center items-center px-4 md:px-20">
      <h1 className="font-semibold text-3xl md:text-5xl my-4 text-center">
        Our Services
      </h1>
      <center>
        <p className="my-8 w-full md:w-2/4 text-justify font-semibold text-black leading-loose text-base md:text-lg">
          A Safety Officer is an invaluable resource, trained to handle
          dangerous situations with expertise and preparedness. One of their
          primary responsibilities is to identify and prevent potential hazards.
          Constantly vigilant, Safety Officers play a crucial role in preventing
          and detecting risky situations, ensuring a safer environment for
          everyone.
        </p>
      </center>

      <div className="pt-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        <div className="p-4 rounded overflow-hidden shadow-lg w-full bg-gradient-to-r from-[#abaaaa] border border-[#F68400] hover:bg-[#545454]">
          <div className="max-w-sm mx-auto">
            <img
              className="h-60 md:h-60 w-full"
              src="/Images/hotel security.png"
              alt="Hotel Security"
            />
            <h6 className="text-xl font-bold my-4 text-justify">
              Hotel Security Services
            </h6>
            <p className="text-black text-justify">
              In the last few decades, the security requirement of a luxury
              hotel or a big restaurant chain has transformed immensely. The
              hotels all over the globe have understood the importance of
              investing greatly into security as the customers have started
              asking for a safe and secure atmosphere.
            </p>
          </div>
        </div>

        <div className="p-4 rounded overflow-hidden shadow-lg w-full bg-gradient-to-r from-[#abaaaa] border border-[#F68400] hover:bg-[#545454]">
          <div className="max-w-sm mx-auto">
            <img
              className="h-60 md:h-60 w-full"
              src="/Images/industrial security.png"
              alt="Industrial Security"
            />
            <h6 className="text-xl font-bold my-4 text-justify">
              Industrial Security Services
            </h6>
            <p className="text-black text-justify">
              In the last few decades, the security requirement of a luxury
              hotel or a big restaurant chain has transformed immensely. The
              hotels all over the globe have understood the importance of
              investing greatly into security as the customers have started
              asking for a safe and secure atmosphere.
            </p>
          </div>
        </div>

        <div className="p-4 rounded overflow-hidden shadow-lg w-full bg-gradient-to-r from-[#abaaaa] border border-[#F68400] hover:bg-[#545454]">
          <div className="max-w-sm mx-auto">
            <img
              className="h-60 md:h-60 w-full"
              src="/Images/corporate security.png"
              alt="Corporate Security"
            />
            <h6 className="text-xl font-bold my-4 text-justify">
              Corporate Security Services
            </h6>
            <p className="text-black text-justify">
              In the last few decades, the security requirement of a luxury
              hotel or a big restaurant chain has transformed immensely. The
              hotels all over the globe have understood the importance of
              investing greatly into security as the customers have started
              asking for a safe and secure atmosphere.
            </p>
          </div>
        </div>

        <div className="p-4 rounded overflow-hidden shadow-lg w-full bg-gradient-to-r from-[#abaaaa] border border-[#F68400] hover:bg-[#545454]">
          <div className="max-w-sm mx-auto">
            <img
              className="h-60 md:h-60 w-full"
              src="/Images/personal security.png"
              alt="Personal Security"
            />
            <h6 className="text-xl font-bold my-4 text-justify">
              Personal Security Services
            </h6>
            <p className="text-black text-justify">
              In the last few decades, the security requirement of a luxury
              hotel or a big restaurant chain has transformed immensely. The
              hotels all over the globe have understood the importance of
              investing greatly into security as the customers have started
              asking for a safe and secure atmosphere.
            </p>
          </div>
        </div>

        <div className="p-4 rounded overflow-hidden shadow-lg w-full bg-gradient-to-r from-[#abaaaa] border border-[#F68400] hover:bg-[#545454]">
          <div className="max-w-sm mx-auto">
            <img
              className="h-60 md:h-60 w-full"
              src="/Images/dog squad.png"
              alt="Dog Squad"
            />
            <h6 className="text-xl font-bold my-4 text-justify">
              Dog Squad Services
            </h6>
            <p className="text-black text-justify">
              In the last few decades, the security requirement of a luxury
              hotel or a big restaurant chain has transformed immensely. The
              hotels all over the globe have understood the importance of
              investing greatly into security as the customers have started
              asking for a safe and secure atmosphere.
            </p>
          </div>
        </div>

        <div className="p-4 rounded overflow-hidden shadow-lg w-full bg-gradient-to-r from-[#abaaaa] border border-[#F68400] hover:bg-[#545454]">
          <div className="max-w-sm mx-auto">
            <img
              className="h-60 md:h-60 w-full"
              src="/Images/lady security.png"
              alt="Lady Security"
            />
            <h6 className="text-xl font-bold my-4 text-justify">
              Lady Security Services
            </h6>
            <p className="text-black text-justify">
              In the last few decades, the security requirement of a luxury
              hotel or a big restaurant chain has transformed immensely. The
              hotels all over the globe have understood the importance of
              investing greatly into security as the customers have started
              asking for a safe and secure atmosphere.
            </p>
          </div>
        </div>

        <div className="p-4 rounded overflow-hidden shadow-lg w-full bg-gradient-to-r from-[#abaaaa] border border-[#F68400] hover:bg-[#545454]">
          <div className="max-w-sm mx-auto">
            <img
              className="h-60 md:h-60 w-full"
              src="/Images/fire squad.png"
              alt="Fire Squad"
            />
            <h6 className="text-xl font-bold my-4 text-justify">
              Fire Squad Services
            </h6>
            <p className="text-black text-justify">
              In the last few decades, the security requirement of a luxury
              hotel or a big restaurant chain has transformed immensely. The
              hotels all over the globe have understood the importance of
              investing greatly into security as the customers have started
              asking for a safe and secure atmosphere.
            </p>
          </div>
        </div>
      </div>
      <center className="pt-10">
        <button className="mb-8 py-3 px-6 text-[#F68400] font-semibold text-sm border-2 rounded-none border-[#F68400] hover:text-white hover:bg-[#F68400]">
          <a href="/services">VIEW ALL SERVICES</a>
        </button>
      </center>
    </div>
  );
};

export default Services;
