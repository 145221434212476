import Head from "../components/header/Head.js";
import Footer1 from "../components/Footer/Footer1.js";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { Routes, Route } from "react-router-dom";
import { useState } from "react";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

export const MainAbout = () => {
  const [counterOn, setCounterOn] = useState(false);

  return (
    <>
      <div className="bg-[#18181A]">
        <Routes>
          <Route index element={<Head />} />
        </Routes>
        <div className="w-full h-96 flex flex-col justify-center items-center mb-20">
          <h1 className="text-white font-semibold text-7xl">About</h1>
          <hr className="border-t-2 border-[#F68400] w-12 mt-8" />
        </div>
      </div>
      <div className="bg-[#F7F7F8]">
        <div className="flex flex-col lg:flex-row lg:justify-between px-4 lg:px-20">
          <h1 className="text-4xl lg:text-5xl font-semibold leading-snug lg:leading-normal mb-4 lg:mb-0">
            Your Trusted <br />
            Security Partner
          </h1>
          <p className="text-[#565A61] w-full lg:w-1/2 text-base lg:text-lg leading-loose text-justify">
            Special Agency is a top notch security company providing best
            security services in complete Delhi NCR and UP. Large sections of
            our society rely on private security providers for everyday security
            of their organizations. We work on PAN India basis. Our company has
            a long list of satisfied customers to which our company is making a
            significant contribution to provision of security.
          </p>
        </div>
        <hr className="my-8 lg:my-16" />

        <div className=" px-36 pb-10 lg:pb-14">
          <ScrollTrigger
            className=" text-6xl lg:text-8xl text-[#565A61] font-semibold px-4 lg:px-16"
            onEnter={() => setCounterOn(true)}
            onExit={() => setCounterOn(false)}
          >
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 justify-between mt-10">
              <div>
                {counterOn && (
                  <CountUp start={0} end={12} duration={2} delay={1} />
                )}
                +
                <p className="text-[#565A61] font-semibold  text-base lg:text-lg">
                  Years in Business
                </p>
              </div>
              <div>
                {counterOn && (
                  <CountUp start={0} end={500} duration={2} delay={1} />
                )}
                +
                <p className="text-[#565A61] font-semibold  text-base lg:text-lg">
                  Happy Customers
                </p>
              </div>
              <div>
                {counterOn && (
                  <CountUp start={0} end={128} duration={2} delay={1} />
                )}
                <p className="text-[#565A61] font-semibold  text-base lg:text-lg">
                  Licensed Officer
                </p>
              </div>
              <div>
                {counterOn && (
                  <CountUp start={0} end={2} duration={2} delay={1} />
                )}
                K+
                <p className="text-[#565A61] font-semibold  text-base lg:text-lg">
                  Assets Protected{" "}
                </p>
              </div>
            </div>
          </ScrollTrigger>
        </div>
      </div>
      <div className="pt-16 bg-white grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 justify-center mb-16">
        <div className="col-span-1 lg:col-span-1 p-8 max-w-full overflow-hidden shadow-lg w-full justify-center mb-8 bg-gradient-to-r from-transparent to-[#abaaaa] hover:bg-[#545454]">
          <div className="flex flex-col lg:flex-row">
            <p className="px-4 lg:px-16 text-[#F68400] text-left font-semibold text-3xl lg:text-5xl">
              Mission
            </p>
            <p className="text-base lg:text-lg font-medium mt-4 lg:mt-0">
              We create strong value and integrity from the basic care of
              existence. Our team is committed to deliver consistent level of
              product and security services with best quality. We aim to provide
              employment to the folk thereby reducing the rate of unemployment
              and providing awareness among youth regarding the safety and
              security needs. We also aim to be the best security providing
              company thereby our services are more reliable, safe and
              innovative.
            </p>
          </div>
        </div>
        <div className="col-span-1 lg:col-span-1 p-8 max-w-full overflow-hidden shadow-lg w-full justify-center mb-8 bg-gradient-to-r from-[#abaaaa] hover:bg-[#545454]">
          <div className="flex flex-col lg:flex-row">
            <p className="text-base lg:text-lg font-medium text-right mt-4 lg:mt-0">
              To provide professional and process oriented services for
              maximized effectiveness at a great level. We aim at providing
              quality based security services with labour welfare with proper
              compliances in order to provide best security and audit based
              services. We ensure to provide quality standards with long lasting
              customer relations with no compromise.
            </p>
            <p className="px-4 lg:px-16 text-[#F68400] text-right font-semibold text-3xl lg:text-5xl mt-4 lg:mt-0">
              Vision
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg-grid-cols-2 gap-4 px-[10%] pb-10">
        <div className="p-2 max-w-lg rounded overflow-hidden shadow-lg justify-center bg-[#F68400] ">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <div className="h-[40vh] md:h-[50vh] lg:h-[60vh]">
              <Viewer fileUrl="Images/ISO 9001 AB SECURITY AND PLACEMENT SERVICES_page-0001[1].pdf" />
            </div>
          </Worker>
          <p className="text-base md:text-lg lg:text-lg font-medium p-2 text-white text-left">
            We are ISO 9001:2015 certified, reflecting our commitment to quality
            services and continuous improvement.
          </p>
        </div>

        <div className="p-2 max-w-lg rounded overflow-hidden shadow-lg justify-center bg-[#F68400]">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <div className="h-[40vh] md:h-[50vh] lg:h-[60vh]">
              <Viewer fileUrl="Images/2442[1].pdf" />
            </div>
          </Worker>
          <p className="text-base md:text-lg lg:text-lg font-medium p-2 text-white text-left">
            We are also licensed by Government of Uttar Pradesh, ensuring that
            we meet the highest industry standards.
          </p>
        </div>
      </div>

      {/* <div className=" grid grid-cols-2 gap-2 px-36 pb-16">
        <div className="p-2  max-w-lg  rounded overflow-hidden shadow-lg justify-center bg-[#F68400]">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer fileUrl="Images/ISO 9001 AB SECURITY AND PLACEMENT SERVICES_page-0001[1].pdf" />
          </Worker>
          <p className="text-lg font-medium p-2 text-white text-left items-center ">
            Our company is ISO 9001:2015 certified, reflecting our commitment to
            quality services and continuous improvement.
          </p>
        </div>
        <div className="p-2 max-w-lg rounded overflow-hidden shadow-lg w-full justify-center  bg-[#F68400]">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer fileUrl="Images/2442[1].pdf" />
          </Worker>
          <p className="text-lg font-medium text-white text-left items-center ">
            We are also licensed by Government of Uttar Pradesh, ensuring that
            we meet the highest industry standards.
          </p>
        </div>
      </div> */}

      <Routes>
        <Route index element={<Footer1 />} />
      </Routes>
    </>
  );
};
