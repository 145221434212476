import React from "react";
import Head from "../components/header/Head.js";
import Footer1 from "../components/Footer/Footer1.js";
import { Routes, Route } from "react-router-dom";
import { useState } from "react";
import { FaBuilding } from "react-icons/fa";
import { GiSkills } from "react-icons/gi";
import { FaEye } from "react-icons/fa6";
import { MdOutlineSecurity } from "react-icons/md";
import { GiLevelFourAdvanced } from "react-icons/gi";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import ContactHomePage from "../components/Contact/ContactHomePage.js";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const Home = () => {
  const [counterOn, setCounterOn] = useState(false);

  return (
    <>
      <div className="relative w-full h-auto md:h-2/3 top-0">
        <Routes>
          <Route index element={<Head />} />
        </Routes>
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <img
          src="/Images/homePage2.png"
          alt="Security_banner_image"
          className="w-full h-auto object-cover"
        />
        <div className="absolute inset-0 flex flex-col justify-center items-end p-4 md:p-20">
          <div className="text-white max-w-md md:max-w-lg ">
            <h1 className=" mb-8 animate-text bg-gradient-to-r from-white via-gray-500 to-[#F68400] bg-clip-text text-transparent text-4xl md:text-6xl font-black leading-normal">
              AB Security and Placement Services
            </h1>
            <p className="font-semibold mb-4 text-base md:text-xl">
              Your Trusted Shield for Safety and Security.
            </p>
            <hr className="w-1/2 my-4 border-gray-500" />
            <p className="font-semibold mb-8">ISO 9001:2015</p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 ">
        <div className=" flex-1 bg-[#666666] text-[#25272D] px-16 py-16">
          <h1 className="text-4xl md:text-5xl font-semibold ">
            Why do we provide best Business Services
          </h1>
          <ul>
            <li className="mt-8 ml-8  text-base md:text-base font-semibold text-white list-disc">
              Security is our core passion and commitment.
            </li>
            <li className="mt-4 ml-8  text-base md:text-base font-semibold text-white list-disc">
              Accredited with ISO 9001-2024 certification.
            </li>
            <li className="mt-4 ml-8  text-base md:text-base font-semibold text-white list-disc">
              Specializing in the security of prestigious organizations and
              VIPs.
            </li>
            <li className="mt-4 ml-8  text-base md:text-base font-semibold text-white list-disc">
              Delivering dedicated service excellence since 2018.
            </li>
            <li className="mt-4 ml-8  text-base md:text-base font-semibold text-white list-disc">
              Premier security organization in Northern India, led by highly
              experienced management.
            </li>
            <li className="mt-4 ml-8  text-base md:text-base font-semibold text-white list-disc">
              Equipped with state-of-the-art communication systems.
            </li>
            <li className="mt-4 ml-8  text-base md:text-base font-semibold text-white list-disc">
              Maintaining a 24/7 operational control room.
            </li>
            <li className="mt-4 ml-8  text-base md:text-base font-semibold text-white list-disc">
              Deploying Quick Reaction Teams (QRT) for continuous vigilance,
              with strategically stationed transport.
            </li>
            <li className="mt-4 ml-8  text-base md:text-base font-semibold text-white list-disc">
              Ensuring uncompromising round-the-clock supervision.{" "}
            </li>
            <li className="mt-4 ml-8  text-base md:text-base font-semibold text-white list-disc">
              Offering mobile supervision services through unannounced spot
              checks.
            </li>
          </ul>
        </div>
        <div className="flex-1 bg-[#F68400] px-8 py-16">
          <h1 className="text-2xl font-semibold text-white pb-10 pl-16">
            Let Numbers Talk
          </h1>
          <ScrollTrigger
            className="text-8xl text-white font-semibold px-10 "
            onEnter={() => setCounterOn(true)}
            onExit={() => setCounterOn(false)}
          >
            <div className="grid grid-cols-2 md:grid-cols-1 lg:grid-cols-2 justify-between mt-10 gap-10">
              <div>
                {counterOn && (
                  <CountUp start={0} end={12} duration={2} delay={1} />
                )}
                +
                <p className="text-white font-semibold text-lg">
                  Years in Business
                </p>
              </div>
              <div>
                {counterOn && (
                  <CountUp start={0} end={500} duration={2} delay={1} />
                )}
                +
                <p className="text-white font-semibold text-lg">
                  Happy Customers
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-1 lg:grid-cols-2 justify-between mt-32 md:mt-10 lg:mt-32 gap-10">
              <div>
                {counterOn && (
                  <CountUp start={0} end={128} duration={2} delay={1} />
                )}
                <p className="text-white font-semibold text-lg">
                  Licensed Officer
                </p>
              </div>
              <div>
                {counterOn && (
                  <CountUp start={0} end={2} duration={2} delay={1} />
                )}
                K+
                <p className="text-white font-semibold text-lg">
                  Assets Protected{" "}
                </p>
              </div>
            </div>
          </ScrollTrigger>
        </div>
      </div>

      <div className="text-center m-20">
        <h1 className="text-5xl font-semibold ">Our Presences </h1>
        <div className=" mt-14 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mx-12">
          <img
            className="h-60 w-70 hover:focus-within:"
            src="/Images/hotel security.png"
            alt="Hotel Security"
          ></img>
          <img
            className="h-60 w-70 hover:focus-within:"
            src="/Images/hotel security.png"
            alt="Hotel Security"
          ></img>
          <img
            className="h-60 w-70 hover:focus-within:"
            src="/Images/hotel security.png"
            alt="Hotel Security"
          ></img>
          <img
            className="h-60 w-70 hover:focus-within:"
            src="/Images/hotel security.png"
            alt="Hotel Security"
          ></img>
        </div>
      </div>
      {/* Why choose us section */}
      <div className="lg:relative w-full">
        <div className="lg:relative w-full h-[100%]">
          <div className="lg:absolute inset-0 bg-black bg-opacity-80"></div>
          <img
            src="/Images/Banner1.png"
            alt="Security_banner_image"
            className="hidden lg:block w-full min-h-full object-cover"
          />
        </div>

        <div className="lg:absolute justify-center z-20 px-4 md:px-8 lg:px-16 text-[#F68400] top-0 lg:mt-16 w-full">
          <div className="text-center ">
            <p className="font-semibold text-center mb-2 text-sm md:text-base lg:text-lg">
              WHY CHOOSE US
            </p>
            <h1 className="font-semibold text-center text-3xl md:text-4xl lg:text-5xl w-full">
              What Sets Us Apart
            </h1>
          </div>
        </div>

        <div className="lg:absolute top-0 mt-20 lg:mt-60 mx-4 md:mx-8 lg:mx-16 right-0 left-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 z-10">
          <div className="w-full text-center mb-8">
            <div className="max-w-sm mx-auto">
              <div className="flex justify-center">
                <FaBuilding className="text-[#F68400] text-3xl md:text-5xl" />
              </div>
              <h6 className="text-xl font-bold my-4 lg:text-white">
                Own Training Center
              </h6>
              <p className="lg:text-white leading-loose text-xs md:text-base">
                Special Agent Security centre equipped with state-of-the-art
                indoor and outdoor training facilities to impart realistic and
                effective training to security guards/professionals.
              </p>
            </div>
          </div>

          <div className="w-full text-center mb-8">
            <div className="max-w-sm mx-auto">
              <div className="flex justify-center">
                <GiSkills className="text-[#F68400] text-4xl md:text-5xl" />
              </div>
              <h6 className="text-xl font-bold my-4 lg:text-white">
                Skill Development and Training
              </h6>
              <p className="lg:text-white leading-loose text-xs md:text-base">
                It is mandatory for all security guards to undergo training as
                per Special Agent Security Training curriculum and acquire
                necessary skills before being deployed with our clients. In
                addition to basic guard skills, they are also taught basics of
                firefighting, first aid, and verbal communication techniques. In
                contribution to basic training, the guards are imparted
                on-the-job training to hone their skills peculiar to each
                client's job requirement.
              </p>
            </div>
          </div>

          <div className="w-full text-center mb-8">
            <div className="max-w-sm mx-auto">
              <div className="flex justify-center">
                <FaEye className="text-[#F68400] text-4xl md:text-5xl" />
              </div>
              <h6 className="text-xl font-bold my-4 lg:text-white">
                Supervision
              </h6>
              <p className="lg:text-white leading-loose text-xs md:text-base">
                Special Agent Security has a well-worked-out supervision process
                to ensure that our quality standards are being met and delivered
                through our services. Continuous supervision through patrolling
                staff and surprise night checks by middle and senior management.
              </p>
            </div>
          </div>

          <div className="w-full text-center mb-8">
            <div className="max-w-sm mx-auto">
              <div className="flex justify-center">
                <MdOutlineSecurity className="text-[#F68400] text-4xl md:text-5xl" />
              </div>
              <h6 className="text-xl font-bold my-4 lg:text-white">
                Quality Policy
              </h6>
              <p className="lg:text-white leading-loose .text-xs md:text-base">
                We stand committed to providing customized safety and security
                solutions/services to all our customers and work endlessly to
                become efficient at all processes. It is our constant endeavor
                to provide the customer error-free products and services with
                the highest standards.
              </p>
            </div>
          </div>

          <div className="w-full text-center mb-8">
            <div className="max-w-sm mx-auto">
              <div className="flex justify-center">
                <GiLevelFourAdvanced className="text-[#F68400] text-3xl md:text-5xl" />
              </div>
              <h6 className="text-xl font-bold my-4 lg:text-white">
                Advanced Technology
              </h6>
              <p className="lg:text-white leading-loose text-xs md:text-base">
                Our officers are equipped with the latest gadgets and technology
                to meet today's security needs.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="lg:relative w-full">
        <div className="lg:relative w-full h-[100%]">
          <div className="lg:absolute inset-0 bg-black bg-opacity-80"></div>
          <img
            src="/Images/Banner1.png"
            alt="Security_banner_image"
            className="w-full min-h-full object-cover"
          />
        </div>
        <div className="absolute top-0 left-0 right-0 flex flex-col justify-center  text-white z-10 px-4 md:px-8 lg:px-16 mt-16">
          <div className="text-center">
            <p className="font-semibold mb-2 text-sm md:text-base lg:text-lg">
              WHY CHOOSE US
            </p>
            <h1 className="font-semibold text-3xl md:text-4xl lg:text-5xl">
              What Sets Us Apart
            </h1>
          </div>
        </div>
        <div className="absolute  top-0 mt-40 lg:mt-60 mx-4 md:mx-8 lg:mx-16 right-0 left-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 z-10">
          <div className="w-full text-center mb-8">
            <div className="max-w-sm mx-auto">
              <div className="flex justify-center">
                <FaBuilding className="text-[#F68400] text-3xl md:text-5xl" />
              </div>
              <h6 className="text-xl font-bold my-4 text-white">
                Own Training Center
              </h6>
              <p className="text-white leading-loose text-xs md:text-base">
                Special Agent Security centre equipped with state of the art
                indoor and outdoor training facilities to impart realistic and
                effective training to security guards/professionals.
              </p>
            </div>
          </div>

          <div className="w-full text-center mb-8">
            <div className="max-w-sm mx-auto">
              <div className="flex justify-center">
                <GiSkills className="text-[#F68400] text-4xl md:text-5xl" />
              </div>
              <h6 className="text-xl font-bold my-4 text-white">
                Skill Development and Training
              </h6>
              <p className="text-white leading-loose text-xs md:text-base">
                It is mandatory for all security guards to undergo training as
                per Special Agent Security Training curriculum and acquire
                necessary skills before being deployed with our clients. In
                addition to basic guard skills, they are also taught basics of
                firefighting, first aid, and verbal communication techniques. In
                contribution to basic training, the guards are imparted
                on-the-job training to hone their skills peculiar to each
                client's job requirement.
              </p>
            </div>
          </div>

          <div className="w-full text-center mb-8">
            <div className="max-w-sm mx-auto">
              <div className="flex justify-center">
                <FaEye className="text-[#F68400] text-4xl md:text-5xl" />
              </div>
              <h6 className="text-xl font-bold my-4 text-white">Supervision</h6>
              <p className="text-white leading-loose text-xs md:text-base">
                Special Agent Security has a well-worked-out supervision process
                to ensure that our quality standards are being met and delivered
                through our services. Continuous supervision through patrolling
                staff and surprise night checks by middle and senior management.
              </p>
            </div>
          </div>

          <div className="w-full text-center mb-8">
            <div className="max-w-sm mx-auto">
              <div className="flex justify-center">
                <MdOutlineSecurity className="text-[#F68400] text-4xl md:text-5xl" />
              </div>
              <h6 className="text-xl font-bold my-4 text-white">
                Quality Policy
              </h6>
              <p className="text-white leading-loose text-xs md:text-base">
                We stand committed to providing customized safety and security
                solutions/services to all our customers and work endlessly to
                become efficient at all processes. It is our constant endeavor
                to provide the customer error-free products and services with
                the highest standards.
              </p>
            </div>
          </div>

          <div className="w-full text-center mb-8">
            <div className="max-w-sm mx-auto">
              <div className="flex justify-center">
                <GiLevelFourAdvanced className="text-[#F68400] text-3xl md:text-5xl" />
              </div>
              <h6 className="text-xl font-bold my-4 text-white">
                Advanced Technology
              </h6>
              <p className="text-white leading-loose text-xs md:text-base">
                Our officers are equipped with the latest gadgets and technology
                to meet today's security needs.
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <div className="m-16 text-center">
        <h1 className="text-5xl font-semibold  text-[#F68400]">
          Clients We Are Serving
        </h1>
        <div className=" mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 mx-10 items-center pl-12">
          <img
            className="h-30 w-30 hover:focus-within:"
            src="/Images/Client-1.png"
            alt="Hotel Security"
          ></img>
          <img
            className="h-30 w-30 hover:focus-within:"
            src="/Images/Client-2.png"
            alt="Hotel Security"
          ></img>
          <img
            className="h-30 w-30 hover:focus-within:"
            src="/Images/Client-9.png"
            alt="Hotel Security"
          ></img>
          <img
            className="h-30 w-30 hover:focus-within:"
            src="/Images/Client-10.png"
            alt="Hotel Security"
          ></img>
          <img
            className="h-30 w-30 hover:focus-within:"
            src="/Images/Client-7.png"
            alt="Hotel Security"
          ></img>
        </div>
      </div>
      <Routes>
        <Route index element={<ContactHomePage />} />
      </Routes>
      <Routes>
        <Route index element={<Footer1 />} />
      </Routes>
    </>
  );
};

export default Home;
