import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaTimes } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";

const Head = () => {
  const [open, setOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        className={`flex flex-row items-center px-4 md:px-10 lg:px-20 h-16 lg:h-24 z-50 sticky top-0 w-full transition-colors duration-300 ${
          scrolled ? "bg-black" : "bg-opacity-0"
        }`}
      >
        <img
          src="/Images/4.png"
          alt="logo"
          className=" mt-6 md:mt-10 w-16 h-16 md:w-24 md:h-24 lg:w-32 lg:h-32"
        />
        <div className="flex-1 text-gray-600 font-semibold">
          <ul className="hidden md:flex flex-row gap-4 lg:gap-12 text-sm lg:text-base justify-center">
            <li className="hover:text-white">
              <Link to="/home">HOME</Link>
            </li>
            <li className="hover:text-white">
              <a href="/about">ABOUT US</a>
            </li>
            <li className="hover:text-white">
              <a href="/services">SERVICES</a>
            </li>
            <li className="hover:text-white">
              <a href="/contact">CONTACT US</a>
            </li>
          </ul>
        </div>

        <button className="hidden md:block py-2 px-4 md:px-6 lg:px-10 text-[#F68400] font-semibold text-sm border-2 rounded border-[#F68400] hover:text-white hover:bg-[#F68400]">
          Login
        </button>

        <div className="md:hidden">
          <button
            className="text-gray-400 hover:text-white focus:outline-none z-50"
            onClick={handleClick}
          >
            {open ? (
              <FaTimes className="w-6 h-6" />
            ) : (
              <GiHamburgerMenu className="w-6 h-6" />
            )}
          </button>
        </div>
      </div>

      {open && (
        <div className="rounded shadow-lg shadow-[#0e0e0e] md:hidden fixed  top-10 right-0 w-full h-full max-w-60 max-h-80 bg-[#18181A] bg-opacity-90 flex flex-col items-center justify-center z-40">
          <button className=" md:block py-2 px-2 md:px-6 lg:px-10 text-[#F68400] font-semibold text-sm border-2 rounded-full border-[#F68400] hover:text-white hover:bg-[#F68400]">
            <FaUserAlt />
          </button>
          <ul className="text-gray-400 text-center leading-normal font-semibold text-xl">
            <li className="hover:text-white py-4">
              <Link to="/home" onClick={handleClick}>
                HOME
              </Link>
            </li>
            <li className="hover:text-white py-4">
              <a href="/about" onClick={handleClick}>
                ABOUT US
              </a>
            </li>
            <li className="hover:text-white py-4">
              <a href="/services" onClick={handleClick}>
                SERVICES
              </a>
            </li>
            <li className="hover:text-white py-4">
              <a href="/contact" onClick={handleClick}>
                CONTACT US
              </a>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default Head;
