import { text } from "@fortawesome/fontawesome-svg-core";
import React from "react";

const ContactHomePage = () => {
  return (
    <div className="">
      <div className="flex flex-row ">
        <img
          className="flex-1 w-1/2 hidden  md:block lg:block"
          src="/Images/Banner1.png"
          alt="security man"
        ></img>
        <div className="flex-1 bg-[#F68400] px-8 py-16">
          <h1 className="text-5xl font-semibold text-[#18181A] pb-10 pl-16">
            Request Info
          </h1>
          <form className="px-16">
            <label className="flex flex-row font-semibold text-white mb-1 ">
              Name <span className="text-red-600">*</span>
            </label>
            <input
              className=" pl-4 flex-1 h-11 w-full"
              type="text"
              value={text}
              placeholder="Full Name"
            ></input>

            <label className="flex flex-row font-semibold text-white mb-1 ">
              Email <span className="text-red-600">*</span>
            </label>
            <input
              className=" pl-4 flex-1 h-11 w-full"
              type="email"
              value={text}
              placeholder="Email Address"
            ></input>
            <label className="flex flex-row font-semibold text-white mb-1 ">
              Company <span className="text-red-600">*</span>
            </label>
            <input
              className=" pl-4 flex-1 h-11 w-full"
              type="text"
              value={text}
              placeholder="Company Name"
            ></input>
            <label className="flex flex-row font-semibold text-white mb-1 ">
              Message <span className="text-red-600">*</span>
            </label>
            <input
              className=" pl-4 flex-1 h-16 w-full"
              type="note"
              value={text}
              placeholder="Your Message Here..."
            ></input>

            <div className="mt-6 mb-6">
              <button className="py-2 px-6 text-[#18181A] font-semibold text-sm border-2 rounded-none border-[#18181A]  hover:text-white hover:bg-[#18181A] ">
                <a href="/home">CONTACT US</a>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactHomePage;
