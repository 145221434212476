import Head from "../components/header/Head.js";
import Footer1 from "../components/Footer/Footer1.js";
import Services from "../components/services/Services.js";
import { Routes, Route } from "react-router-dom";

export const MainServices = () => {
  return (
    <>
      <div className="bg-[#18181A]">
        <Routes>
          <Route index element={<Head />} />
        </Routes>
        <div className="w-full h-96 flex flex-col justify-center items-center mb-20">
          <h1 className="text-white font-semibold text-7xl">Services</h1>
          <hr className="border-t-2 border-[#F68400] w-12 mt-8" />
        </div>
      </div>

      <Routes>
        <Route index element={<Services />} />
      </Routes>
      <Routes>
        <Route index element={<Footer1 />} />
      </Routes>
    </>
  );
};
