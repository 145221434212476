import React from "react";
import Head from "../components/header/Head.js";
import Footer1 from "../components/Footer/Footer1.js";
import { Routes, Route } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaGoogle } from "react-icons/fa";

const Contact = () => {
  return (
    <>
      <div className="bg-[#18181A]">
        <Routes>
          <Route index element={<Head />} />
        </Routes>

        <div className="flex flex-col lg:flex-row px-6 md:px-20 lg:px-36 py-12 md:py-24">
          <div className="flex-1 items-center">
            <h1 className="text-5xl md:text-7xl font-semibold text-[#F68400]">
              Contact
            </h1>
            <hr className="mt-4 md:mt-8 border border-[#F68400] w-12" />

            <p className="text-[#565A61] font-semibold text-xl md:text-3xl mt-4">
              Feel free to contact us for any questions or doubts.
            </p>
            <p className="text-[#565A61] font-semibold mt-8 md:mt-12">
              KEEP IN TOUCH
            </p>
            <div className="flex flex-row gap-6 pt-6">
              <FaGoogle className="w-6 h-6 text-[#565A61] hover:text-[#F68400]" />
              <FaTwitter className="w-6 h-6 text-[#565A61] hover:text-[#F68400]" />
              <FaFacebook className="w-6 h-6 text-[#565A61] hover:text-[#F68400]" />
              <FaLinkedin className="w-6 h-6 text-[#565A61] hover:text-[#F68400]" />
            </div>
          </div>

          <div className="flex-1 mt-8 lg:mt-0">
            <form className="px-4 md:px-16">
              <label className="flex flex-row font-semibold text-white mb-1">
                Name <span className="text-red-600">*</span>
              </label>
              <input
                className="pl-4 flex-1 h-11 w-full"
                type="text"
                placeholder="Full Name"
              />

              <label className="mt-4 flex flex-row font-semibold text-white mb-1">
                Email <span className="text-red-600">*</span>
              </label>
              <input
                className="pl-4 flex-1 h-11 w-full"
                type="email"
                placeholder="Email Address"
              />

              <label className="mt-4 flex flex-row font-semibold text-white mb-1">
                Company <span className="text-red-600">*</span>
              </label>
              <input
                className="pl-4 flex-1 h-11 w-full"
                type="text"
                placeholder="Company Name"
              />

              <label className="mt-4 flex flex-row font-semibold text-white mb-1">
                Message <span className="text-red-600">*</span>
              </label>
              <textarea
                className="pl-4 flex-1 h-32 w-full"
                placeholder="Your Message Here..."
              ></textarea>

              <div className="mt-6 mb-6">
                <button className="py-2 px-6 text-[#F68400] font-semibold text-sm border-2 border-[#F68400] hover:text-white hover:bg-[#F68400]">
                  CONTACT US
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="bg-[#9b9ba1] flex flex-col lg:flex-row justify-between px-6 md:px-20 lg:px-36 py-12 md:py-16">
        <div className="lg:w-1/3 mb-8 lg:mb-0">
          <div className="flex flex-row gap-2">
            <FaLocationDot className="w-6 h-6 text-[#18181A]" />
            <h6 className="font-semibold text-[#18181A]">HEAD OFFICE</h6>
          </div>
          <p className="text-white mt-3 text-lg">
            KHS - 208, OD - 100127, Baidpura, Greater Noida, Gautam Buddha Nagar
            Uttar Pradesh - 203207, India
          </p>
        </div>

        <div className="lg:w-1/3 mb-8 lg:mb-0">
          <div className="flex flex-row gap-2">
            <MdEmail className="w-6 h-6 text-[#18181A]" />
            <h6 className="font-semibold text-[#18181A]">EMAIL</h6>
          </div>
          <p className="text-white mt-3 text-lg">info@specialsecurity.com</p>
        </div>

        <div className="lg:w-1/3">
          <div className="flex flex-row gap-2">
            <FaPhoneAlt className="w-6 h-6 text-[#18181A]" />
            <h6 className="font-semibold text-[#18181A]">PHONE</h6>
          </div>
          <p className="text-white mt-3 text-lg">092898 15124</p>
          <p className="text-white mt-3 text-lg">098732 22202</p>
        </div>
      </div>

      <div className="w-full h-64 md:h-96">
        <iframe
          className="w-full h-full"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d112203.12329596234!2d77.4309186320426!3d28.499187802659513!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ced0079ddb4ff%3A0xfb58ab4578ba88cf!2sBaidpura!5e0!3m2!1sen!2sin!4v1725623011872!5m2!1sen!2sin"
          allowFullScreen=""
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <Routes>
        <Route index element={<Footer1 />} />
      </Routes>
    </>
  );
};

export default Contact;

// import React from "react";
// import Head from "../components/header/Head.js";
// import Footer1 from "../components/Footer/Footer1.js";
// import { Routes, Route } from "react-router-dom";
// import { FaLocationDot } from "react-icons/fa6";
// import { MdEmail } from "react-icons/md";
// import { FaPhoneAlt } from "react-icons/fa";
// import { FaTwitter } from "react-icons/fa6";
// import { FaFacebook } from "react-icons/fa";
// import { FaLinkedin } from "react-icons/fa";
// import { FaGoogle } from "react-icons/fa";

// const Contact = () => {
//   return (
//     <>
//       <div className="bg-[#18181A] ">

//         <Routes>
//           <Route index element={<Head />} />
//         </Routes>
//         <div className="flex flex-row  px-36 py-24">
//           <div className="flex-1 items-center">
//             <h1 className="text-7xl font-semibold text-[#F68400] ">
//               {" "}
//               Contact{" "}
//             </h1>
//             <hr className=" mt-8 border border-[#F68400] w-12" />

//             <p className="text-[#565A61] font-semibold text-3xl w-2/3 mt-4">
//               Feel free to conatact us for any questions and doubts.
//             </p>
//             <p className="text-[#565A61] font-semibold mt-12">KEEP IN TOUCH</p>
//             <div className="flex flex-row gap-6 pt-6">
//               <FaGoogle className="w-6 h-6 text-[#565A61] hover hover:text-[#F68400]" />
//               <FaTwitter className="w-6 h-6 text-[#565A61] hover hover:text-[#F68400]" />
//               <FaFacebook className="w-6 h-6 text-[#565A61]  hover hover:text-[#F68400]" />
//               <FaLinkedin className="w-6 h-6 text-[#565A61]  hover hover:text-[#F68400]" />
//             </div>
//           </div>

//           <div className="flex-1">
//             <form className="px-16">
//               <label className="flex flex-row font-semibold text-white mb-1 ">
//                 Name <span className="text-red-600">*</span>
//               </label>
//               <input
//                 className=" pl-4 flex-1 h-11 w-full"
//                 type="text"
//                 placeholder="Full Name"
//               ></input>

//               <label className=" mt-4 flex flex-row font-semibold text-white mb-1">
//                 Email <span className="text-red-600">*</span>
//               </label>
//               <input
//                 className=" pl-4 flex-1 h-11 w-full"
//                 type="email"
//                 placeholder="Email Address"
//               ></input>
//               <label className=" mt-4 flex flex-row font-semibold text-white mb-1">
//                 Company <span className="text-red-600">*</span>
//               </label>
//               <input
//                 className=" pl-4 flex-1 h-11 w-full"
//                 type="text"
//                 placeholder="Company Name"
//               ></input>
//               <label className=" mt-4 flex flex-row font-semibold text-white mb-1">
//                 Message <span className="text-red-600">*</span>
//               </label>
//               <input
//                 className=" pl-4 flex-1 h-16 w-full"
//                 type="note"
//                 placeholder="Your Message Here..."
//               ></input>

//               <div className="mt-6 mb-6">
//                 <button className="py-2 px-6 text-[#F68400] font-semibold text-sm border-2 rounded-none border-[#F68400]  hover:text-white hover:bg-[#F68400]">
//                   CONTACT US
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//       {/* Second component of the same page */}
//       <div className=" bg-[#9b9ba1]  justify-between  px-36 py-16">
//         <div className=" grid grid-cols-3 gap-44">
//           <div>
//             <div className="flex flex-row gap-2">
//               <FaLocationDot className="w-6 h-6 text-[#18181A]" />
//               <h6 className=" font-semibold text-[#18181A]"> HEAD OFFICE</h6>
//             </div>
//             <p className="text-white mt-3 text-lg ">
//               KHS - 208, OD - 100127, Baidpura, Greater Noida, Gautam Buddha
//               Nagar Uttar Pradesh - 203207, India
//             </p>
//           </div>
//           <div>
//             <div className="flex flex-row gap-2">
//               <MdEmail className="w-6 h-6 text-[#18181A]" />
//               <h6 className=" font-semibold text-[#18181A]"> EMAIL</h6>
//             </div>
//             <p className="text-white mt-3 text-lg">info@specialsecurity.com</p>
//           </div>
//           <div>
//             <div className="flex flex-row gap-2">
//               <FaPhoneAlt className="w-6 h-6 text-[#18181A]" />
//               <h6 className=" font-semibold text-[#18181A]"> PHONE</h6>
//             </div>
//             <p className="text-white mt-3 text-lg">092898 15124</p>
//             <p className="text-white mt-3 text-lg">098732 22202</p>
//           </div>
//         </div>
//         <div className="py-12">
//           <iframe
//             className="w-full h-96"
//             src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d112203.12329596234!2d77.4309186320426!3d28.499187802659513!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ced0079ddb4ff%3A0xfb58ab4578ba88cf!2sBaidpura!5e0!3m2!1sen!2sin!4v1725623011872!5m2!1sen!2sin"
//             referrerpolicy="no-referrer-when-downgrade"
//           ></iframe>
//         </div>
//       </div>
//       <Routes>
//         <Route index element={<Footer1 />} />
//       </Routes>
//     </>
//   );
// };

// export default Contact;
